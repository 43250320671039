import React from "react"
import {
    H1,
    H2,
    H3,
    H4,
    H5,
    Text,
    Paragraph,
} from "../components/UI/Typography"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/core"

const Render = () => (
    <Layout>
        <SEO />
        <H1
            css={css`
                background-color: rgba(255, 0, 0, 1);
            `}
        >
            The quick brown fox jumps over the lazy dog
        </H1>
        <H2
            css={css`
                background-color: rgba(255, 0, 0, 0.8);
            `}
        >
            The quick brown fox jumps over the lazy dog
        </H2>
        <H3
            css={css`
                background-color: rgba(255, 0, 0, 0.6);
            `}
        >
            The quick brown fox jumps over the lazy dog
        </H3>
        <H4
            css={css`
                background-color: rgba(255, 0, 0, 0.4);
            `}
        >
            The quick brown fox jumps over the lazy dog
        </H4>
        <H5
            css={css`
                background-color: rgba(255, 0, 0, 0.2);
            `}
        >
            The quick brown fox jumps over the lazy dog
        </H5>
        <Text
            css={css`
                background-color: rgba(0, 255, 0, 1);
            `}
        >
            The quick brown fox jumps over the lazy dog
        </Text>
        <Paragraph
            css={css`
                background-color: rgba(0, 255, 0, 0.8);
            `}
        >
            The quick brown fox jumps over the lazy dog
        </Paragraph>
    </Layout>
)

export default Render
